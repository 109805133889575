.table_review {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef3;
    padding: 40px 0px;
}

.table_review_responsive {
    width: 100%;
    /* border: 1px solid #00bcd4; */
    background-color: #efefef33;
    /* padding: 15px; */
    overflow: auto;
    margin-top: 25px;
    /* margin: auto; */
    border-radius: 4px;
}

.responsive_review {
    width: 92%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 3px rgba(32, 32, 32, .3);
}

.responsive_review .head_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    /* background-color: pink; */
}

.responsive_review .head_card .search_patient {
    display: flex;
    align-items: center;
    justify-content: center;
}

.responsive_review .head_card .search_patient h6 {
    margin-right: 10px;
    margin-top: 5px;
}

.responsive_review .head_card .search_patient .search {
    /* background-color: white; */
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #00bcd4;
}

/*  */






/* Review Card */
.responsive_review .Update_Card {
    width: 100%;
    padding: 0px 15px 10px 15px;
    border-radius: 10px;
}

.responsive_review .Update_Card .loader_review {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.responsive_review .Update_Card .loader_review p {
    margin-top: 10px;
}

.responsive_review .Update_Card .Noreview {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.responsive_review .Update_Card .review_all {
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    border-radius: 15px;
    border: 3px solid whitesmoke;
    display: flex;
}

.responsive_review .Update_Card .review_all img {
    width: 110px;
    height: 110px;
    border-radius: 10px;
    object-fit: cover;
}

.responsive_review .Update_Card .review_all .review_details {
    padding: 0px 20px 0px 20px;
    width: 100%;
}

.responsive_review .Update_Card .review_all .review_details p {
    margin-top: 10px;
    color: gray;
}

.responsive_review .Update_Card .review_all .review_details .bottom_deatil_review {
    display: flex;
    margin-top: 10px;
    text-align: center;
    justify-content: space-between;
}

.responsive_review .Update_Card .review_all .review_details .bottom_deatil_review .star {
    color: #fcb71b;
    margin-left: 10px;
}

.responsive_review .Update_Card .review_all .review_details .bottom_deatil_review .rev_approv {
    padding: 5px 10px 5px 10px;
    background-color: #e91e63;
    border-radius: 8px;
    border: 1px solid whitesmoke;
    color: white;
}


.action_btn {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.action_btn button {
    text-decoration: none;
    color: #444;
    background-color: #e91e63;
    border: 1px solid orange;
    display: inline-block;
    padding: 15px 30px;
    font-weight: bold;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
}

.action_btn button:nth-child(1) {
    border-color: #e91e63;
    color: white;
}

.action_btn button:nth-child(2) {
    border-color: gray;
    background-color: white;
}

.action_btn button:hover {
    box-shadow: 0px 3px 8px #0003;
}

/*  */



.Update_Card .update_input {
    /* margin: 0px 10px; */
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #29166f; */
}

.Update_Card .update_input .update_width_subject {
    width: 96%;
    margin-bottom: 15px;
    /* background-color: #514c65; */
}

.Update_Card .update_input .update_width_subject #update_inpu {
    width: 100%;
    height: 160px;
    border: 0.1px solid gray;
    padding: 12px;
    border-radius: 8px;
}


.Update_Card .Updatebuttun {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.Update_Card .Updatebuttun .submit {
    padding: 14px 43px;
    border-radius: 5px;
    margin: 5px;
    background-color: #19c8de;
    color: white;
    border: 1px solid #19c8de;
    font-size: medium;
    transition: 0.3s ease-in-out;
}

.Update_Card .Updatebuttun #reset {
    background-color: #fda600;
    border-color: #fda600;
}

.Update_Card .Updatebuttun #submite:hover {
    background-color: #f1f5fc;
    color: #19c8de;
}

.Update_Card .Updatebuttun #loader:hover {
    box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

.Update_Card .Updatebuttun #reset:hover {
    background-color: #f1f5fc;
    color: #fda600;
}

#update_country {
    width: 100%;
    border: 0.1px solid gray;
    padding: 12px;
    border-radius: 8px;
}

.update_countrry {
    margin-top: 10px;
    width: 100%;
}


.succeess_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 20px;
    margin-top: 10px;
    width: 100%;
}

.succeess_modal button {
    text-decoration: none;
    color: #444;
    background-color: #19c8de;
    border: 1px solid #19c8de;
    display: inline-block;
    padding: 15px 30px;
    font-weight: bold;
    width: 25%;
    border-radius: 12px;
    color: white;
    transition: 0.3s ease-in-out;
    align-self: center;
}

.succeess_modal button:nth-child(1) {
    border-color: #e91e63;
    color: white;
}

.succeess_modal button:hover {
    background-color: white;
    border: 1px solid #19c8de;
    box-shadow: 0px 3px 8px #0003;
    color: black;
}





/* ///////////////////////// */
.update_input .table_responsiv_city {
    width: 95%;
    background-color: #efefef33;
    overflow: auto;
    margin-top: 25px;
    border-radius: 4px;
}

.update_input .table_responsiv_city .table_tag {
    width: 100%;
    font-size: 13px;
    color: #444;
    white-space: nowrap;
    border-collapse: collapse;
}

.update_input .table_responsiv_city .table_tag th {
    background-color: #00bcd4;
    color: #fff;
    text-transform: uppercase;
}

.update_input .table_responsiv_city .table_tag th {
    padding: 10px;
}

.update_input .table_responsiv_city .table_tag th,
.update_input .table_responsiv_city .table_tag td {
    border: 1px solid #00000017;
    padding: 10px 15px;
}

.update_input .table_responsiv_city .table_tag tr td img {
    display: inline-block;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px #0003;
}

.update_input .table_responsiv_city .table_tag .aution_btn {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.update_input .table_responsiv_city .table_tag .aution_btn button {
    text-decoration: none;
    color: #444;
    background-color: #fff;
    border: 1px solid orange;
    display: inline-block;
    padding: 7px 30px;
    font-weight: bold;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
}

.update_input .table_responsiv_city .table_tag .aution_btn button:nth-child(1) {
    border-color: #26a69a;
}

.update_input .table_responsiv_city .table_tag .aution_btn button:nth-child(2) {
    border-color: orange;
}

.update_input .table_responsiv_city .table_tag .aution_btn button:hover {
    box-shadow: 0px 3px 8px #0003;
}

.update_input .table_responsiv_city .table_tag tr {
    background-color: #fff;
    transition: 0.3s ease-in-out;
}

.update_input .table_responsiv_city .table_tag tr:nth-child(odd) {
    /* background-color: rgb(238, 238, 238); */
    background-color: #eeeeee;
}

.update_input .table_responsiv_city .table_tag tr:hover {
    filter: drop-shadow(0px 2px 6px #0002);
}