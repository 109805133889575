.main_Login_div {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #eaeef3;
}
.main_Login_div .both_handle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_Login_div .both_handle img {
    width: 105px;
    height: 105px;
}
.main_Login_div .box {
    width: 100%;
    padding: 50px;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0px 0px 2px 0px #888;;
    /* box-shadow: inset 0px 0px 25px 0px #888; */
    border-radius: 5px;
}

.main_Login_div .box h3 {
    color: #4f698e;
    text-align: center;
    text-transform: capitalize;
}
.main_Login_div .box h5 {
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 40px;
    color: gray;
    text-align: center;
}
.main_Login_div .box .input_box {
    position: relative;
}

.box .input_box input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    color: gray;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
}

.box .input_box label {
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: 1px;
    padding: 12px 15px;
    font-size: 16px;
    color: gray;
    transition: 0.5s;
}

.box input[type="submit"] {
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    background: #19c8de;
    padding: 9px 18px;
    border-radius: 5px;
    font-size: 14px;
}

.box .input_box input:focus~label,
.box .input_box input:valid~label {
    top: -22px;
    left: 0;
    font-size: 13px;
    color: #19c8de;
}

.box .link {
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    background: #19c8de;
    padding: 9px 18px;
    border-radius: 5px;
    font-size: large;
}

.box #delete_loader{
    transition: 0.3s ease-in-out;
    align-self: center;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    background: #19c8de;
    padding: 9px 18px;
    border-radius: 5px;
    font-size: large;
}